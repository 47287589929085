import { HttpClient } from '@wix/yoshi-flow-editor';
import {
  listCategories,
  listQuestions,
} from '@wix/ambassador-faq-v1-question-entry/http';

export class FAQService {
  faqService: HttpClient;
  constructor(instance: string) {
    this.faqService = new HttpClient({
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getListCategories = async () => {
    const response = await this.faqService.request(listCategories({}));
    return response.data;
  };

  getListQuestions = async () => {
    const response = await this.faqService.request(listQuestions({}));
    return response.data;
  };
}
