import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import HeaderWrapper from './Components/HeaderWrapper/HeaderWrapper';
import TabsWrapper from './Components/Tabs/TabsWrapper';
import { AppContext } from './Components/SettingsProvider/SettingsProvider';

const Widget: FC<WidgetProps> = (props) => {
  const instance = props.instance;
  const baseURL = props.baseURL;

  return (
    <AppContext.Provider value={{ instance, baseURL }}>
      <TPAComponentsProvider value={{}}>
        <HeaderWrapper />
        <TabsWrapper />
      </TPAComponentsProvider>
    </AppContext.Provider>
  );
};

export default Widget;
