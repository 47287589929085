import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import settingsParams from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';

const HeaderWrapper = () => {
  const settings: ISettingsContextValue = useSettings();

  const { text, show, alignment } = settings.get(settingsParams.title);

  return (
    <div style={{ textAlign: alignment }}>
      <Text typography={TextTypography.largeTitle}>{show && text}</Text>
    </div>
  );
};

export default HeaderWrapper;
