import React, { useState } from 'react';
import { TextField, TextFieldTheme } from 'wix-ui-tpa';
import { Search } from '@wix/wix-ui-icons-common/on-stage';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';

const SearchWrapper = (props: {
  onSearchChange: (searchTerm: string) => void;
}) => {
  const { onSearchChange } = props;
  const settings: ISettingsContextValue = useSettings();
  const { text } = settings.get(settingsParams.searchBar);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearchChange(value);
  };

  return (
    <TextField
      value={searchTerm}
      theme={TextFieldTheme.Line}
      onChange={handleSearchChange}
      placeholder={text}
      suffix={<Search />}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearchChange(searchTerm);
        }
      }}
    />
  );
};

export default SearchWrapper;
