import { createContext } from 'react';

// Define the interface for the context's value
export interface AppContextInterface {
  instance: string;
  baseURL: string;
}

// Create the context and provide a default value that matches the interface
export const AppContext = createContext<AppContextInterface>({
  instance: '',
  baseURL: '',
});
