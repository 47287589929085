import React, { useContext } from 'react';
import { CopyUrlButton, SocialBar } from 'wix-ui-tpa';
import { Facebook, XCorp, LinkedIn } from '@wix/wix-ui-icons-common/on-stage';
import settingsParams from '../../../settingsParams';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';

enum SocialMedia {
  Facebook = 'facebook',
  Twitter = 'twitter',
  LinkedIn = 'linkedin',
  Copy = 'copy',
}

const SocialWrapper = (props: { questionId: string }) => {
  const settings: ISettingsContextValue = useSettings();
  const { baseURL } = useContext(AppContext);
  const env = useEnvironment();
  const { appDefinitionId } = env;
  const { questionId } = props;
  const { facebook, twitter, linkedin, copyQuestionLink } = settings.get(
    settingsParams.social,
  );
  const { t } = useTranslation();
  const isEditor = env.isEditor || env.isPreview;

  const socialShare = (media: SocialMedia) => {
    const navigationURL = `${baseURL}?questionId=${questionId}&appDefId=${appDefinitionId}`;
    let url = '';
    switch (media) {
      case SocialMedia.Facebook:
        url = 'https://www.facebook.com/sharer/sharer.php?u=';
        break;
      case SocialMedia.Twitter:
        url = 'https://twitter.com/intent/tweet?url=';
        break;
      case SocialMedia.LinkedIn:
        url = 'https://www.linkedin.com/shareArticle?mini=true&url=';
        break;
      case SocialMedia.Copy:
        return navigationURL;
      default:
        url = '';
    }

    if (url !== '') {
      url += navigationURL;

      window.open(url, '_blank', 'top=200, left=200, width=700, height=500');
    }

    return url;
  };

  return (
    <div style={{ display: 'flex' }}>
      <SocialBar>
        {facebook && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.facebook')
            }
            icon={<Facebook />}
            onClick={() => socialShare(SocialMedia.Facebook)}
          />
        )}

        {twitter && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.twitter')
            }
            icon={<XCorp />}
            onClick={() => socialShare(SocialMedia.Twitter)}
          />
        )}

        {linkedin && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.linkedin')
            }
            icon={<LinkedIn />}
            onClick={() => socialShare(SocialMedia.LinkedIn)}
          />
        )}

        {copyQuestionLink && (
          <CopyUrlButton
            tooltipText={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.copyQuestionLink')
            }
            successText={t('display.tab.social.copiedQuestionLink')}
            url={socialShare(SocialMedia.Copy)}
          />
        )}
      </SocialBar>
    </div>
  );
};

export default SocialWrapper;
