import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { FAQService } from '../../../utils/FAQService';
import QuestionsWrapper from '../QuestionsWrapper/QuestionsWrapper';
import SearchWrapper from '../SearchWrapper/SearchWrapper';
import { Card, Tabs, TabsAlignment, TabsSkin } from 'wix-ui-tpa';
import styles from './styles.css';
import settingsParams from '../../../settingsParams';
import constants from '../../../../../constants';
import { Category } from '@wix/ambassador-faq-v1-question-entry/build/cjs/types.impl';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';

const TabsWrapper = () => {
  const settings: ISettingsContextValue = useSettings();
  const { instance } = useContext(AppContext);
  const { show } = settings.get(settingsParams.searchBar);
  const displayContent = settings.get(settingsParams.displayContent);
  const { showAll, categories } = settings.get(settingsParams.manageCategories);
  const splitIntoCategories =
    displayContent === constants.DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES;
  const oneListOfQuestions =
    displayContent === constants.DISPLAY_CONTENT.ONE_LIST_OF_QUESTIONS;
  const api = useMemo(() => new FAQService(instance), [instance]);
  const [listCategories, setListCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const asyncFun = async () => {
      const { categories } = await api.getListCategories();
      setListCategories(categories as []);
    };
    asyncFun();
  }, [api]);

  const handleSearchChange = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);

  const categoriesToRender = useMemo(() => {
    if (showAll) {
      return listCategories;
    }
    if (categories && categories.length > 0) {
      return listCategories.filter((category: Category) =>
        categories.includes(category?.id!),
      );
    }

    return [];
  }, [showAll, categories, listCategories]);

  const adjustedActiveTab = useMemo(() => {
    if (
      categoriesToRender.length > 0 &&
      activeTab >= categoriesToRender.length
    ) {
      return 0;
    }
    return activeTab;
  }, [activeTab, categoriesToRender]);

  return (
    <>
      <Card className={styles.container}>
        <Card className={styles.tabsContainer}>
          {!searchTerm && splitIntoCategories && (
            <Tabs
              items={categoriesToRender}
              activeTabIndex={adjustedActiveTab}
              onTabClick={setActiveTab}
              skin={TabsSkin.clear}
              alignment={TabsAlignment.left}
            />
          )}
        </Card>
        <Card>
          {show && <SearchWrapper onSearchChange={handleSearchChange} />}
        </Card>
      </Card>
      <QuestionsWrapper
        oneListOfQuestions={oneListOfQuestions}
        searchTerm={searchTerm}
        activeTabIndex={categoriesToRender[adjustedActiveTab]}
      />
    </>
  );
};

export default TabsWrapper;
