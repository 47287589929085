import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FAQService } from '../../../utils/FAQService';
import { Accordion, Text } from 'wix-ui-tpa';
import {
  Category,
  QuestionEntry,
} from '@wix/ambassador-faq-v1-question-entry/types';
import SocialWrapper from '../SocialWrapper/SocialWrapper';
import { useTranslation } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';

const QuestionsWrapper = (props: {
  activeTabIndex: Category;
  searchTerm: string;
  oneListOfQuestions: boolean;
}) => {
  const { activeTabIndex, searchTerm, oneListOfQuestions } = props;
  const settings: ISettingsContextValue = useSettings();
  const { instance } = useContext(AppContext);
  const { showAll, categories } = settings.get(settingsParams.manageCategories);
  const { t } = useTranslation();
  const api = useMemo(() => new FAQService(instance), [instance]);
  const [questionsList, setQuestionsList] = useState([]);

  useEffect(() => {
    const asyncFun = async () => {
      const { questions } = await api.getListQuestions();
      setQuestionsList(questions as []);
    };
    asyncFun();
  }, [api]);

  const parseDraftJS = (draftJSString: string) => {
    try {
      const parsed = JSON.parse(draftJSString);
      return parsed.blocks
        .map((block: { text: string }) => block.text)
        .join('\n');
    } catch (err) {
      console.log('Error parsing answer', err);
      return '';
    }
  };

  const filteredQuestions = searchTerm
    ? questionsList.filter((item: QuestionEntry) => {
        const questionText = item.question?.toLowerCase() || '';
        const answerText = parseDraftJS(
          item?.answer?.draftjs || '',
        ).toLowerCase();
        const searchQuery = searchTerm.toLowerCase();

        return (
          questionText.includes(searchQuery) || answerText.includes(searchQuery)
        );
      })
    : oneListOfQuestions && showAll
    ? questionsList
    : oneListOfQuestions && categories.length > 0
    ? questionsList.filter(
        (item: QuestionEntry) =>
          item.categoryId && categories.includes(item.categoryId),
      )
    : questionsList.filter(
        (item: QuestionEntry) => item.categoryId === activeTabIndex?.id,
      );

  return filteredQuestions.length > 0 ? (
    <Accordion>
      {filteredQuestions.map((item: QuestionEntry) => (
        <Accordion.Item key={item?.id} id={item?.id!} title={item?.question!}>
          {parseDraftJS(item?.answer?.draftjs!)}
          <SocialWrapper questionId={item?.id!} />
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <Text>{t('widget.search.tab.no.results.found')}</Text>
  );
};

export default QuestionsWrapper;
